import React, {cloneElement, createRef, useCallback, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/ViewCertificate.css';

// react router
import {useHistory} from 'react-router-dom';

// reactstrap
import {Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import Spinner from '../components/common/Spinner.js';
import {
    CapitalLettersCell,
    ColumnMenu,
    DateCell,
    DateIsExpiredCell,
    NoWrapCell,
    TextAlignMiddleCell
} from "../components/common/Grid.js";
import Alert from '../components/common/Alert.js';

// kendo react
import {getSelectedState, Grid, GridColumn as Column, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {ExcelExport, ExcelExportColumn} from '@progress/kendo-react-excel-export';
import {Button} from '@progress/kendo-react-buttons';
import {getter} from '@progress/kendo-react-common';
import {orderBy, process} from '@progress/kendo-data-query';
import {fileExcelIcon} from "@progress/kendo-svg-icons";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    exportAllExcelKey,
    findViewCertKey,
    loadingPeriodsKey,
    certNumberKey,
    poNumberKey,
    genericEmptyGridKey,
    genericErrorTitleKey,
    contactUsHeaderKey,
    descriptionKey,
    endDateKey,
    productKey,
    qtyPurchasedKey,
    qtyRemainingKey,
    startDateKey,
    termKey,
    requestLicensesNowKey,
    myCertKey,
    orderNumberKey,
    profileItarTitleKey,
    profileItarMessageKey,
    entitlementOnHostTitleKey,
    entitlementOnHostMessageKey,
    attentionExclamationKey,
    profileItarMessageKeyTwo,
    profileItarMessageKeyThree,
    profileItarMessageLACKey, entitlementCodeKey, activationCodeKey
} from '../assets/text/MultilingualText.js';
import {AnchorWrap, Text} from "../components/common/MultilingualText";
import {Tooltip} from "@progress/kendo-react-tooltip";

// consts for my certificates table
const DATA_ITEM_KEY = 'ORDER_ID';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);
const initialGridState = {
    take: 10, skip: 0,
};

function ViewCertificate(props) {
    const {
        accessToken,
        siteLanguageDefault,
        setShowRestrictedPopup,
        restrictedFlag,
        lacFlag,
    } = useContext(UserContext);
    const localization = useLocalization();

    let viewCertHeader = localization.toLanguageString(
        findViewCertKey,
        mainMessages[siteLanguageDefault][findViewCertKey]
    );

    let viewCertSubheaderMyCert = localization.toLanguageString(
        myCertKey,
        mainMessages[siteLanguageDefault][myCertKey]
    );

    let viewCertSubheaderOrderNumber = localization.toLanguageString(
        orderNumberKey,
        mainMessages[siteLanguageDefault][orderNumberKey]
    );

     let viewCertSubheaderCertNumber = localization.toLanguageString(
        certNumberKey,
        mainMessages[siteLanguageDefault][certNumberKey]
    );

    let viewCertExcelExport = localization.toLanguageString(
        exportAllExcelKey,
        mainMessages[siteLanguageDefault][exportAllExcelKey]
    );

    // let viewCertViewPDF = localization.toLanguageString(
    //     viewPdfKey,
    //     mainMessages[siteLanguageDefault][viewPdfKey]
    // );

    let history = useHistory();

    const [certificates, setCertificates] = useState([]);
    const [currentSelectedState, setCurrentSelectedState] = useState({});
    const [certificateGridState, setCertificateGridState] = useState(initialGridState);
    const [certificateState, setCertificateState] = useState(
        process(certificates.map((certificate) => ({
            ...certificate,
            'selected': currentSelectedState[idGetter(certificate)],
        })), initialGridState)
    );
    const [orderId, setOrderId] = useState("");
    const [disableRequestLicense, setDisableRequestLicense] = useState(false)

    const [orderNumber, setOrderNumber] = useState("");
    const [certNumber, setCertNumber] = useState("");
    const defaultGridMessage = localization.toLanguageString(
        genericEmptyGridKey,
        mainMessages[siteLanguageDefault][genericEmptyGridKey]
    );
    const loadingGridMessage = localization.toLanguageString(
        loadingPeriodsKey,
        mainMessages[siteLanguageDefault][loadingPeriodsKey]
    );
    const [emptyGridMessage, setEmptyGridMessage] = useState(defaultGridMessage)
    const [productDetailsError, setProductDetailsError] = useState(false);
    const [itarErrors, setItarErrors] = useState([]);
    const [itarProfileError, setItarProfileError] = useState(false);
    const [disabled, setDisabled] = useState(true);

    /*
     * setSelectedCertificate(certs) sets the selected flag of a certificate
     * @param {certs} the list of certificates
     * @return {selected} the list of certificates wth the selected certificate's flag set
    */
    const setSelectedCertificate = (certificates: any[]) => {
        let selected = certificates.map((certificate) => {
            if (certificate.items) {
                return {
                    ...certificate, items: setSelectedCertificate(certificate.items),
                };
            } else {
                return {
                    ...certificate, 'selected': currentSelectedState[idGetter(certificate)],
                };
            }
        });
        return selected;
    };

    /*
     * onCertificateSelectionChange(event) sets the certificate grid state on grid's selection change
     * @param {event} the grid selection change event
    */
    const onCertificateSelectionChange = useCallback((event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event, selectedState: currentSelectedState, dataItemKey: DATA_ITEM_KEY,
        });
        setCurrentSelectedState(newSelectedState);
    }, [currentSelectedState]);

    /*
     * onCertificateRowClick(props) sets the order id and order number for header above products table
     * @param {props} the props
    */
    const onCertificateRowClick = (props) => {
        const orderId = props.dataItem.ORDER_ID
        const orderNumber = props.dataItem.SOURCE_ORDER_NUMBER
        const certNumber = props.dataItem.CERTIFICATE_ID
        setOrderId(orderId);
        setOrderNumber(orderNumber);
        setCertNumber(certNumber);
        getProductDetailsByOrderID(orderId)
    }

    /*
     * onCertificateStateChange(event) sets the certificate grid state and certificate state on grid's state change
     * @param {event} the grid selection change event
    */
    const onCertificateStateChange = useCallback((event: GridDataStateChangeEvent) => {
        const newCertificateState = process(certificates.map((certificate) => ({
            ...certificate, 'selected': currentSelectedState[idGetter(certificate)],
        })), event.dataState);

        setCertificateGridState(event.dataState);
        setCertificateState(newCertificateState);
    }, [certificates]); // eslint-disable-line react-hooks/exhaustive-deps

    const certificateData = setSelectedCertificate(certificateState.data);

    const [products, setProducts] = useState([]);
    const createProductState = (productState) => {
        return {
            result: process(products.slice(0), productState), dataState: productState
        };
    };
    let initialProductState = createProductState({
        take: Infinity, skip: 0,
    });
    const [productResult, setProductResult] = useState(initialProductState.result);
    const [productState, setProductState] = useState(initialProductState.dataState);
    const productStateChange = (event) => {
        let updatedState = createProductState(event.dataState);
        setProductResult(updatedState.result);
        setProductState(updatedState.dataState);
    };

    const [userExcelData, setUserExcelData] = useState([]);
    const [excelFileName, setExcelFileName] = useState("");

    const [isLoading, setIsLoading] = useState(true);

    // const viewPDFStyle = {
    //     float: "right"
    // };

    // kendo react excel export
    const _exporter = createRef();
    const exportExcel = () => {
        if (_exporter.current) {
            _exporter.current.save();
        }
    };

    /*
     * getProductDetailsByOrderID() gets all the products for a selected certificate
    */
    const getProductDetailsByOrderID = (orderId) => {
        setItarErrors([]);
        let headers = {
            'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken
        };
        let data = {
            "order_id": orderId
        }
        setIsLoading(true);
        setEmptyGridMessage(loadingGridMessage)

        axios.post(config.certificate_module.PRODUCT_DETAILS, data, {headers: headers})
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    if (data?.data) setProducts(data?.data);
                    if (data.CANREQUESTLICENSE === 'TRUE') {
                        setDisableRequestLicense(false)
                    } else {
                        setDisableRequestLicense(true)
                    }
                    if (response?.data?.status_code === "MOD_ERROR_200"){
                        if (response?.data?.code) {
                            setItarErrors(response?.data?.code)
                            if (response?.data?.code.includes("MOD_CERT_210")) {
                                setItarProfileError(true);
                            }
                        }
                    }
                }
                setIsLoading(false);
                setProductDetailsError(false);
                setEmptyGridMessage(defaultGridMessage)
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Order Product details", error);
                setIsLoading(false);
                setProductDetailsError(true);
            });
    }

    /*
     * getOrderCertificatePDF() gets the pdf of a certificate
    */
    // const getOrderCertificatePDF = () => {
    //     let body = {"order_id": orderId}
    //     let headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + accessToken
    //     };
    //
    //     setIsLoading(true);
    //
    //     axios.post(
    //         config.certificate_module.VIEW_ORDER_PDF,
    //         body,
    //         {headers: headers}
    //     )
    //         .then((response) => {
    //             if (response.status === 200) {
    //                 console.log("Excel got success response");
    //             }
    //             setIsLoading(false);
    //         })
    //         .catch((error) => {
    //             console.log("ERROR: Failed to load pdf", JSON.parse(error.request.response));
    //             setIsLoading(false);
    //         });
    // }

    // Adobe Analytics
    useEffect(() => {
        window.adobeDataLayer.push({
            "page": {
                "pageName": "View Certificate"
            }
        })
    }, [])

    useEffect(() => {
        /*
         * getExcelData() gets all certificates and certificate details data for an excel export
        */
        async function getExcelData() {
            let headers = {
                'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken
            };
            axios.get(config.certificate_module.EXCEL_EXPORT, {headers: headers})
                .then((response) => {
                    if (response.status === 200) {
                        let data = response.data || [];

                        // format dates for excel export
                        data = data.map(excelData => {
                            const options = {day: 'numeric', month: 'short', year: 'numeric'};
                            let startDate = excelData['START_DATE'] ? new Date(excelData['START_DATE']).toLocaleDateString('en-GB', options).replace(/ /g, '-').toUpperCase() : excelData['START_DATE'];
                            let endDate = excelData['END_DATE'] ? new Date(excelData['END_DATE']).toLocaleDateString('en-GB', options).replace(/ /g, '-').toUpperCase() : excelData['END_DATE'];

                            excelData['START_DATE'] = startDate;
                            excelData['END_DATE'] = endDate;

                            return excelData;
                        });

                        setUserExcelData(data);
                        var today = new Date(),
                            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                        setExcelFileName('KSM_CERTIFICATES_' + date + '.xlsx');
                        setDisabled(false);
                    }
                })
                .catch((error) => {
                    console.log("ERROR: Failed to GET excel export data", error);
                });
        }

        getExcelData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        /*
         * getUserCertificateDetails() gets login user tagged certificates with order id/number
        */
        async function getUserCertificateDetails() {
            let headers = {
                'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken
            };

            setIsLoading(true);
            setEmptyGridMessage(loadingGridMessage)

            axios.get(config.certificate_module.VIEW_CERTIFICATES, {headers: headers})
                .then((response) => {
                    if (response.status === 200) {
                        let data = response.data || [];
                        data = data.map((item) => Object.assign({
                            selected: false,
                        }, item))
                        // sort the data by order number
                        let orderByOrderNumber = orderBy(data, [{
                            field: "SOURCE_ORDER_NUMBER",
                            dir: "asc"
                        }]);
                        setCertificates(orderByOrderNumber);
                        setIsLoading(false);
                    }
                    setEmptyGridMessage(defaultGridMessage)
                })
                .catch((error) => {
                    console.log("ERROR: Failed to GET View User Certificates Data", error);
                    setIsLoading(false);
                    history.push('/error');
                });
        }

        getUserCertificateDetails();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // initialize my certificates table
    useEffect(() => {
        if (certificates.length) {
            setCertificateState(process(certificates.map((certificate) => ({
                ...certificate, 'selected': currentSelectedState[idGetter(certificate)],
            })), initialGridState));
        }
    }, [certificates]); // eslint-disable-line react-hooks/exhaustive-deps

    // initialize certificate details table
    useEffect(() => {
        setProductResult(initialProductState.result);
    }, [products]); // eslint-disable-line react-hooks/exhaustive-deps

    const productDetailsErrorStyle = {
        'width': '40rem'
    }

    const rowRender = (trElement) => {
        const trProps = {
            style: {
                cursor: 'pointer'
            }
        };
        return cloneElement(
            trElement,
            {...trProps},
            trElement.props.children
        );
    };

    // closes the itar success alerts
    const alertItarErrorsHandler = () => {
        setItarErrors([]);
    }

    // closes product details error alert
    const alertproductDetailsErrorHandler = () => {
        setProductDetailsError(false);
    }

    const AnchorText = AnchorWrap(Text);

    // when user accepts itar t&c and is no longer restricted, check if we can enable request license now button
    useEffect(() => {
        let enableButton = false
        if (!restrictedFlag) {
            setItarProfileError(false);
            products.forEach((product) => {
                let endDate = new Date(product?.END_DATE)
                let currentDate = new Date()
                if (product?.ENTITLMENT_TYPE === "LICENSE" && Number(product?.QTY_REMAINING) > 0 && ((product?.END_DATE == null) || (currentDate < endDate))) {
                    enableButton = true
                }
            })
        }
        if (enableButton) setDisableRequestLicense(false);
    }, [restrictedFlag])

    return (
        <>
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <div className="k-h3">{viewCertHeader}</div>
                <div
                    className={'k-mb-2'}
                    style={{
                        display: 'flex',
                        alignItems: 'end',
                        justifyContent: 'space-between',
                        width: '50%'
                    }}
                >
                    <div className="k-h5" style={{marginBottom: '0'}}>
                        {viewCertSubheaderMyCert}
                    </div>
                    <Tooltip
                        anchorElement="target"
                        showCallout={true}
                        parentTitle={true}
                        openDelay={0}
                        position="left"
                    >
                        <Button
                            title={viewCertExcelExport}
                            themeColor={"tertiary"}
                            fillMode={"solid"}
                            disabled={disabled}
                            onClick={exportExcel}
                            svgIcon={fileExcelIcon}
                        />
                    </Tooltip>
                </div>
                {isLoading ? <Spinner/> : <></>}
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>
                    <div style={{minWidth: '50%'}}>
                        <Grid
                            scrollable={"none"}
                            sortable={true}
                            pageable={(certificates.length <= 10) ? false : {
                                buttonCount: 5, pageSizes: [10, 20, 50, 100],
                            }}
                            rowSelection={true}
                            rowRender={rowRender}
                            selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: true, drag: false, cell: false, mode: 'single',
                            }}
                            sort={[{
                                field: "SOURCE_ORDER_NUMBER",
                                dir: "asc",
                            }]}
                            total={certificateState.total}
                            data={certificateData}
                            onDataStateChange={onCertificateStateChange}
                            onSelectionChange={onCertificateSelectionChange}
                            onRowClick={onCertificateRowClick}
                            {...certificateGridState}
                        >
                            <GridNoRecords>
                                {emptyGridMessage}
                            </GridNoRecords>
                            <GridColumn
                                field="SOURCE_ORDER_NUMBER"
                                title={localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey])}
                                className="ksm-blod-blue-cell"
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="CERTIFICATE_ID"
                                title={localization.toLanguageString(certNumberKey, mainMessages[siteLanguageDefault][certNumberKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="ENTITLEMENT_CODE"
                                title={localization.toLanguageString(entitlementCodeKey, mainMessages[siteLanguageDefault][entitlementCodeKey])}
                                columnMenu={ColumnMenu}
                                cell={NoWrapCell}
                            />
                            <GridColumn
                                field="PO_NUMBER"
                                title={localization.toLanguageString(poNumberKey, mainMessages[siteLanguageDefault][poNumberKey])}
                                columnMenu={ColumnMenu}
                            />
                        </Grid>
                    </div>
                    <div>
                        <ExcelExport
                            data={userExcelData}
                            fileName={excelFileName}
                            ref={_exporter}
                        >
                            <ExcelExportColumn
                                field="VENDOR_ORDER_NUM"
                                title={localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey])}
                            />
                            <ExcelExportColumn
                                field="CERTIFICATE_ID"
                                title={localization.toLanguageString(certNumberKey, mainMessages[siteLanguageDefault][certNumberKey])}
                            />
                            <ExcelExportColumn
                                field="PO_NUMBER"
                                title={localization.toLanguageString(poNumberKey, mainMessages[siteLanguageDefault][poNumberKey])}
                            />
                            <ExcelExportColumn
                                field="PROD_NUM"
                                title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                            />
                            <ExcelExportColumn
                                field="DESCRIPTION"
                                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                            />
                            <ExcelExportColumn
                                field="TERM"
                                title={localization.toLanguageString(termKey, mainMessages[siteLanguageDefault][termKey])}
                            />
                            <ExcelExportColumn
                                field="START_DATE"
                                title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                            />
                            <ExcelExportColumn
                                field="END_DATE"
                                title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                            />
                            <ExcelExportColumn
                                field="QTY_ORDERED"
                                title={localization.toLanguageString(qtyPurchasedKey, mainMessages[siteLanguageDefault][qtyPurchasedKey])}
                            />
                            <ExcelExportColumn
                                field="QTY_REMAINING"
                                title={localization.toLanguageString(qtyRemainingKey, mainMessages[siteLanguageDefault][qtyRemainingKey])}
                            />
                        </ExcelExport>
                    </div>
                </div>
                {orderId && orderNumber && <>
                    <div className={'ksm-hr'} />
                    <Row>
                        <div>
                            <h4 className="k-h5">{viewCertSubheaderOrderNumber} {orderNumber} / {viewCertSubheaderCertNumber} {certNumber}</h4>
                            {!productDetailsError && (
                                <>
                                    <Grid
                                        className={"view-cert-order-grid"}
                                        sortable={true}
                                        scrollable={"none"}
                                        data={productResult}
                                        onDataStateChange={productStateChange}
                                        {...productState}
                                    >
                                        <GridNoRecords>
                                            {emptyGridMessage}
                                        </GridNoRecords>
                                        <GridColumn
                                            field="PROD_NUM"
                                            title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                                            columnMenu={ColumnMenu}
                                            cell={NoWrapCell}
                                        />
                                        <Column
                                            field="ACTIVATION_CODE"
                                            title={localization.toLanguageString(activationCodeKey, mainMessages[siteLanguageDefault][activationCodeKey])}
                                            columnMenu={ColumnMenu}
                                            cell={NoWrapCell}
                                        />
                                        <GridColumn
                                            field="DESCRIPTION"
                                            title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                            columnMenu={ColumnMenu}
                                        />
                                        <GridColumn
                                            field="TERM"
                                            title={localization.toLanguageString(termKey, mainMessages[siteLanguageDefault][termKey])}
                                            sortable={false}
                                            cell={CapitalLettersCell}
                                        />
                                        <GridColumn
                                            field="START_DATE"
                                            title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                                            sortable={false}
                                            cell={DateCell}
                                        />
                                        <GridColumn
                                            field="END_DATE"
                                            title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                                            sortable={false}
                                            cell={DateIsExpiredCell}
                                        />
                                        <GridColumn
                                            field="QTY_ORDERED"
                                            title={localization.toLanguageString(qtyPurchasedKey, mainMessages[siteLanguageDefault][qtyPurchasedKey])}
                                            sortable={false}
                                            cell={TextAlignMiddleCell}
                                        />
                                        <GridColumn
                                            field="QTY_REMAINING"
                                            title={localization.toLanguageString(qtyRemainingKey, mainMessages[siteLanguageDefault][qtyRemainingKey])}
                                            sortable={false}
                                            cell={TextAlignMiddleCell}
                                        />
                                    </Grid>

                                    <div style={{
                                        marginTop: '0.5rem',
                                        marginBottom: '0.5rem'
                                    }}>
                                        <Button
                                            themeColor={"primary"}
                                            size={"large"}
                                            fillMode={"solid"}
                                            type={"button"}
                                            name="request-licenses"
                                            disabled={disableRequestLicense}
                                            onClick={() => {
                                                history.push('/request-license?orderID=' + orderId)
                                            }}
                                        >
                                            {localization.toLanguageString(requestLicensesNowKey, mainMessages[siteLanguageDefault][requestLicensesNowKey])}
                                        </Button>
                                    </div>
                                </>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {productDetailsError && (
                                    <div style={productDetailsErrorStyle}>
                                        <Alert
                                            showHandler={alertproductDetailsErrorHandler}
                                            type={'error'}
                                            title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                                            message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                                        />
                                    </div>
                                )}
                                {itarErrors.length > 0 &&
                                itarErrors.includes("MOD_CERT_210") &&
                                itarErrors.includes("MOD_CERT_211") ?
                                    (
                                        <div style={productDetailsErrorStyle}>
                                            <Alert
                                                showHandler={alertItarErrorsHandler}
                                                type={'warning'}
                                                title={
                                                    <Text
                                                        textkey={attentionExclamationKey}
                                                        textdefault={mainMessages[siteLanguageDefault][attentionExclamationKey]}
                                                    />
                                                }
                                                message={
                                                    <ul>
                                                        {(itarProfileError || lacFlag === "Y") && (
                                                            <li>
                                                                <span>
                                                            {lacFlag === "Y" ? (
                                                                <Text
                                                                    textkey={profileItarMessageLACKey}
                                                                    textdefault={mainMessages[siteLanguageDefault][profileItarMessageLACKey]}
                                                                />
                                                            ) : (
                                                                <>
                                                                    <Text
                                                                        textkey={profileItarMessageKey}
                                                                        textdefault={mainMessages[siteLanguageDefault][profileItarMessageKey]}
                                                                    />
                                                                    <AnchorText
                                                                        style={{
                                                                            textUnderlineOffset: '0.188rem',
                                                                            textDecoration: 'underline'
                                                                        }}
                                                                        onClick={() => {
                                                                            setShowRestrictedPopup(true);
                                                                        }}
                                                                        textkey={profileItarMessageKeyTwo}
                                                                        textdefault={mainMessages[siteLanguageDefault][profileItarMessageKeyTwo]}
                                                                    />
                                                                    <Text
                                                                        textkey={profileItarMessageKeyThree}
                                                                        textdefault={mainMessages[siteLanguageDefault][profileItarMessageKeyThree]}
                                                                    />
                                                                </>
                                                            )}
                                                                </span>
                                                            </li>
                                                        )}
                                                        <li>
                                                            <Text
                                                                textkey={entitlementOnHostMessageKey}
                                                                textdefault={mainMessages[siteLanguageDefault][entitlementOnHostMessageKey]}
                                                            />
                                                        </li>
                                                    </ul>
                                                }
                                            />
                                        </div>
                                    ) :
                                    itarErrors.length > 0 &&
                                    itarErrors.includes("MOD_CERT_210") ?
                                        (
                                            <>
                                                { (itarProfileError || lacFlag === "Y") && (
                                                    <div style={productDetailsErrorStyle}>
                                                        <Alert
                                                            showHandler={alertItarErrorsHandler}
                                                            type={'warning'}
                                                            title={
                                                                <Text
                                                                    textkey={attentionExclamationKey}
                                                                    textdefault={mainMessages[siteLanguageDefault][attentionExclamationKey]}
                                                                />
                                                            }
                                                            message={
                                                                lacFlag === "Y" ? (
                                                                    <div>
                                                                        <Text
                                                                            textkey={profileItarMessageLACKey}
                                                                            textdefault={mainMessages[siteLanguageDefault][profileItarMessageLACKey]}
                                                                        />
                                                                    </div>
                                                                    ) : (
                                                                        <div>
                                                                            <Text
                                                                                textkey={profileItarMessageKey}
                                                                                textdefault={mainMessages[siteLanguageDefault][profileItarMessageKey]}
                                                                            />
                                                                            <AnchorText
                                                                                style={{
                                                                                    textUnderlineOffset: '0.188rem',
                                                                                    textDecoration: 'underline'
                                                                                }}
                                                                                onClick={() => {
                                                                                    setShowRestrictedPopup(true);
                                                                                }}
                                                                                textkey={profileItarMessageKeyTwo}
                                                                                textdefault={mainMessages[siteLanguageDefault][profileItarMessageKeyTwo]}
                                                                            />
                                                                            <Text
                                                                                textkey={profileItarMessageKeyThree}
                                                                                textdefault={mainMessages[siteLanguageDefault][profileItarMessageKeyThree]}
                                                                            />
                                                                        </div>
                                                                    )
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        ) :
                                        itarErrors.length > 0 &&
                                        itarErrors.includes("MOD_CERT_211") ?
                                            (
                                                <div style={productDetailsErrorStyle}>
                                                    <Alert
                                                        showHandler={alertItarErrorsHandler}
                                                        type={'warning'}
                                                        title={
                                                            <Text
                                                                textkey={attentionExclamationKey}
                                                                textdefault={mainMessages[siteLanguageDefault][attentionExclamationKey]}
                                                            />
                                                        }
                                                        message={
                                                            <Text
                                                                textkey={entitlementOnHostMessageKey}
                                                                textdefault={mainMessages[siteLanguageDefault][entitlementOnHostMessageKey]}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            ) : <></>
                                }
                            </div>

                        </div>
                    </Row>
                    {/*<Row>*/}
                    {/*    <div>*/}
                    {/*        <Button*/}
                    {/*            themeColor={"secondary"}*/}
                    {/*            size={"large"}*/}
                    {/*            shape={"rectangle"}*/}
                    {/*            fillMode={"solid"}*/}
                    {/*            rounded={"small"}*/}
                    {/*            icon={"file-pdf"}*/}
                    {/*            onClick={getOrderCertificatePDF}*/}
                    {/*            style={{*/}
                    {/*                float: "right"*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            {viewCertViewPDF}*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}
                    {/*</Row>*/}
                </>}
            </div>
        </>
    );
};

export default ViewCertificate;

